@font-face {
  font-family: 'Milky Vintage';
  src: url('../fonts/Milky Vintage.woff2') format('woff2'),
       url('../fonts/Milky Vintage.woff') format('woff'),
       url('../fonts/Milky Vintage.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #8B0000;
  color: #FFE;
  font-family: 'Courier Prime', monospace;
  font-weight: 400;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.header {
  width: 100%;
  position: relative;
  z-index: 2;
}

.header-palms {
  position: absolute;
  top: -150px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-links {
  display: flex;
  list-style: none;
  gap: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: white;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem 0;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

.section-headline {
  position: relative;
  margin: 0;
  padding-bottom: 1.5rem;
}

.section-headline::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 1px;
  background-color: #FFE;
}

.instagram-dm-button,
.social-icons {
  margin-top: 1.5rem;
}

.questions-text {
  margin-top: 0.5rem;
}

.copyright {
  margin-top: 1rem;
}

@media (max-width: 768px) {
    .nav-links {
      display: none;
    }
    
    .section {
      padding: 4rem 1rem;
    }

    .header-palms {
      top: 0;
    }
  }

  html {
    scroll-behavior: smooth;
  }

  .home-logo {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 150px auto 0;
    display: block;
  }

  #home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .snow-shelf {
    width: min(70%, 800px);
    height: auto;
    margin: clamp(-60px, -8vh, -30px) auto;
    position: relative;
    z-index: 2;
    display: block;
  }

  .subheader {
    font-family: cocktail-shaker, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: clamp(4.9rem, 11.2vw, 8.4rem);
    line-height: 0.9;
    margin-top: 0.5rem;
    margin-bottom: clamp(0.25rem, 1vh, 0.5rem);
  }

  .custom-font {
    font-family: 'Courier Prime', monospace;
  }

  .holiday {
    font-family: 'Milky Vintage', sans-serif;
    font-size: clamp(2.925rem, 5.85vw, 4.875rem);
    margin-top: 0;
    margin-bottom: clamp(2rem, 4vh, 3rem);
  }

  .slanted-text {
    transform: skew(-10deg);
    transform: rotate(-5deg);
  }

  .bamboo-button {
    position: relative;
    padding: 0;
    min-width: unset;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bamboo-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 0;
    filter: invert(23%) sepia(29%) saturate(549%) hue-rotate(346deg) brightness(95%) contrast(92%);
  }

  .button-content {
    position: relative;
    background: #184A25;
    padding: clamp(18.75px, 2.5vw, 37.5px) clamp(37.5px, 5vw, 75px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-text {
    position: relative;
    z-index: 1;
    font-family: 'Courier Prime', monospace;
    font-size: clamp(1.25rem, 1.875vw, 1.5rem);
    letter-spacing: 1px;
    color: #FFE;
  }

  #about {
    padding: clamp(2rem, 4vh, 4rem) 0;
    max-width: 800px;
    margin: 0 auto;
  }

  #about p {
    font-family: 'Courier Prime', monospace;
    font-size: clamp(0.9rem, 1.2vw, 1.1rem);
    line-height: 1.75;
    max-width: 65ch;
    margin: 0 auto;
    text-align: center;
  }

  .snow-shelf {
    width: min(70%, 800px);
    height: auto;
    margin: clamp(-60px, -8vh, -30px) auto;
    position: relative;
    z-index: 2;
  }

  #about.section {
    margin-top: clamp(1rem, 2vh, 2rem);
    padding: clamp(1rem, 2vh, 2rem) clamp(2rem, 5vw, 3rem);
    position: relative;
    z-index: 1;
  }

  #about p {
    font-family: 'Courier Prime', monospace;
    font-size: clamp(0.9rem, 1.2vw, 1.1rem);
    line-height: 1.6;
    max-width: 65ch;
    margin: 0 auto;
    text-align: center;
    padding: 0 clamp(1rem, 3vw, 2rem);
  }

  #home.section {
    min-height: 70vh;
    justify-content: center;
    padding-bottom: 0;
    margin-bottom: clamp(-4rem, -8vh, -6rem);
    padding-top: 0;
  }

  .divider-section {
    width: 100%;
    position: relative;
    padding: 0;
    z-index: 2;
  }

  .snow-shelf {
    width: min(70%, 800px);
    height: auto;
    display: block;
    margin: 0 auto;
  }

  #home.section {
    margin-bottom: 0;
  }

  #about.section {
    margin-top: 0;
    padding-top: clamp(1rem, 2vh, 2rem);
  }

  .social-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }

  .social-header {
    font-family: 'Courier Prime', monospace;
    font-weight: 300;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 0.5rem;
  }

  .social-header::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    height: 1px;
    background-color: #FFE;
  }

  .social-icons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  .circle-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FFE;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .instagram::before {
    content: '';
    width: 20px;
    height: 20px;
    background: #8B0000;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'/%3E%3C/svg%3E") center/contain no-repeat;
  }

  .tiktok::before {
    content: '';
    width: 20px;
    height: 20px;
    background: #8B0000;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z'/%3E%3C/svg%3E") center/contain no-repeat;
  }

  footer p:last-child {
    padding-bottom: 1.5rem;
    font-size: 0.7rem;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: clamp(1rem, 2vh, 2rem) 0;
  }

  #about.section {
    margin-top: clamp(1rem, 2vh, 2rem);
    padding: clamp(1rem, 2vh, 2rem) clamp(2rem, 5vw, 3rem);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #about p {
    font-family: 'Courier Prime', monospace;
    font-size: clamp(0.9rem, 1.2vw, 1.1rem);
    line-height: 1.6;
    max-width: 65ch;
    margin: 0 auto;
    text-align: center;
    padding: 0 clamp(1rem, 3vw, 2rem);
    margin-bottom: clamp(2rem, 4vh, 3rem);
  }

  .bamboo-button {
    position: relative;
    padding: 0;
    min-width: unset;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .accordion-section {
    width: 100%;
    max-width: 65ch;
    margin: 2rem auto;
    font-family: 'Courier Prime', monospace;
  }

  .accordion-item {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 800px;
  }

  .accordion-header {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #184A25;
    background-color: #184A25;
    transition: background-color 0.3s ease;
    color: #FFE;
    font-family: 'Courier Prime', monospace;
    font-size: 1rem;
    text-align: left;
  }

  .accordion-header:hover {
    background-color: #1d5b2e;
  }

  .accordion-header.open {
    border-bottom: none;
  }

  .accordion-icon {
    font-size: 1.5rem;
    line-height: 1;
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    border: 1px solid #184A25;
    border-top: none;
    background-color: #184A25;
    padding: 0 1rem;
    font-family: 'Courier Prime', monospace;
    font-size: 1rem;
  }

  .accordion-content.open {
    max-height: 500px;
    padding: 1rem;
  }

  .questions-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    font-family: 'Courier Prime', monospace;
  }

  .instagram-dm-button {
    padding: 0.75rem 1.5rem;
    background: none;
    border: 1px solid #FFE;
    color: #FFE;
    text-decoration: none;
    font-family: 'Courier Prime', monospace;
    margin-top: 1rem;
    transition: background-color 0.3s ease;
  }

  .instagram-dm-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .questions-text {
    font-size: 1rem;
    color: #FFE;
    margin-top: 0.5rem;
  }

  .social-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .social-icons {
    margin-top: 1.5rem;
  }

  footer p {
    margin-top: 1rem;
    text-align: center;
  }

  @media (max-width: 768px) {
    .footer-section {
      margin-bottom: 1.5rem;
    }
  }

.app-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
}

